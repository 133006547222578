<template>
  <b-container class="py-5">
    <h1 class="h2 mb-4">Testimonial</h1>
    <b-row class="mb-4">
      <b-col
        v-for="testimonial in testimonials"
        :key="`testimoni-${testimonial.id}`"
        sm="4"
        class="pb-4"
      >
        <testimoni-card
          :name="testimonial.name"
          :summary="testimonial.summary"
          :occupation="testimonial.occupation"
          :image="testimonial.image"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TestimoniCard from '../components/TestimoniCard.vue'
import testimonials from '../data/testimonials.json'

export default {
  name: 'Testimonials',
  components: {
    TestimoniCard,
  },
  data() {
    return {
      testimonials: testimonials,
    }
  },
}
</script>
