<template>
  <b-card no-body class="mb-4 shadow-sm" border-variant="light">
    <b-card-body class="text-center">
      <b-img
        :src="imagePath"
        rounded="circle"
        class="testimonial-img mb-4"
      ></b-img>
      <b-card-title>{{ name }}</b-card-title>
      <b-card-sub-title class="testimonial-occupation mb-4">{{
        occupation
      }}</b-card-sub-title>
      <b-card-text style="font-size: 0.9em" class="testimonial-summary">
        {{ summary }}
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'TestimoniCard',
  props: {
    name: {
      type: String,
      required: true,
    },
    occupation: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    summary: {
      type: String,
      required: true,
    },
  },
  computed: {
    imagePath() {
      return require(`@/assets/images/testimonials/${this.image}`)
    },
  },
}
</script>
